<template>
  <div class="usereducations">
    <h2 class="h4" v-html="$T('educationsTitle')"/>

    <hr/>

    <b-table
      :busy="is.loading"
      :fields="tableEducations.fields"
      :items="tableEducations.items"
      :sort-compare="sortEducationTable"
      sort-by="event"
      striped
      borderless
      :stacked="$root.mobile.up.key"
      show-empty
      sort-icon-left
    >
      <template #table-busy>
        <div class="text-center">
          <c-loader type="dots" inline scale="4"/>
          <div>{{ $T('table.Busy') }}</div>
        </div>
      </template>

      <template #empty>
        <p class="my-2 text-center">{{ $T('table.Empty') }}</p>
      </template>

      <template #cell(event)="data">
        <div v-for="item in data.value" :key="item.id" class="usereducations-event">
          <h5 class="mb-0">{{ item.location.name }}</h5>
          <p v-if="item.location.description">{{ item.location.description }}</p>

          <div class="mt-1">
            <div v-if="item.location.address">{{ item.location.address }}</div>
            <div v-if="item.location.locality">{{ item.location.locality }}</div>
            <div><timeago :datetime="item.datetime.date" :converter-options="{ format: 'PPPP', forceDateFormat: true }"/>, <timeago :datetime="item.datetime.from" :converter-options="{ format: 'p', forceDateFormat: true }"/> bis <timeago :datetime="item.datetime.to " :converter-options="{ format: 'p', forceDateFormat: true }"/></div>
          </div>
        </div>
      </template>

      <template #cell(type)="data">
        <b-badge :variant="data.item.typeVariant">{{ data.item.typeLabel }}</b-badge>
      </template>

      <template #cell(state)="data">
        <b-badge :variant="data.item.stateVariant">{{ data.item.stateLabel }}</b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="btn-list">
          <b-button
            v-for="(action, aIndex) in data.value"
            :key="aIndex"
            :href="action.url"
            variant="outline-primary"
            size="sm"
          >{{ action.name[$store.getters['gui/getLanguage']] }}</b-button>
        </div>
      </template>
    </b-table>

    <h2 class="h4 mt-5" v-html="$T('elearningsTitle')"/>

    <hr/>

    <b-table
      :busy="is.loading"
      :fields="tableElearnings.fields"
      :items="tableElearnings.items"
      striped
      borderless
      :stacked="$root.mobile.up.key"
      show-empty
      sort-icon-left
    >
      <template #table-busy>
        <div class="text-center">
          <c-loader type="dots" inline scale="4"/>
          <div>{{ $T('table.Busy') }}</div>
        </div>
      </template>

      <template #empty>
        <p class="my-2 text-center">{{ $T('table.Empty') }}</p>
      </template>

      <template #cell(type)="data">
        <b-badge :variant="data.item.typeVariant">{{ data.item.typeLabel }}</b-badge>
      </template>

      <template #cell(state)="data">
        <b-badge :variant="data.item.stateVariant">{{ data.item.stateLabel }}</b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="btn-list">
          <b-button
            v-for="(action, aIndex) in data.value"
            :key="aIndex"
            :href="action.url"
            variant="outline-primary"
            size="sm"
          >{{ action.name[$store.getters['gui/getLanguage']] }}</b-button>
        </div>
      </template>
    </b-table>

    <!-- TODO: integrate EducationProofs -->
    <h2 class="h4 mt-5" v-html="$T('proofsTitle')"/>

    <hr/>

    <b-table
      :busy="is.loading"
      :fields="tableProofs.fields"
      :items="tableProofs.items"
      striped
      borderless
      :stacked="$root.mobile.up.key"
      show-empty
      sort-icon-left
    >
      <template #table-busy>
        <div class="text-center">
          <c-loader type="dots" inline scale="4"/>
          <div>{{ $T('table.Busy') }}</div>
        </div>
      </template>

      <template #empty>
        <p class="my-2 text-center">{{ $T('table.Empty') }}</p>
      </template>

      <template #cell(completionDate)="data">
        <timeago :datetime="data.value" :converterOptions="{ format: 'PPP' }" auto-update/>
      </template>

      <template #cell(documents)="data">
        <div class="btn-list">
          <b-button
            v-for="(document, dIndex) in data.value"
            :key="dIndex"
            :href="document.downloadUrl"
            target="_blank"
            variant="outline-primary"
            size="sm"
            download
          >{{ document.title }}</b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { EDUCATION_VARIANT_TYPES, EDUCATION_TYPE_VARIANTS, EDUCATION_STATE_VARIANTS } from '@/assets/js/config/server'

import Table from '@/assets/js/helper/table'

const ENTITY_KEY = 'usereducations'

export default {
  name: 'Users.Educations',
  data () {
    return {
      entityKey: ENTITY_KEY,
      table: {
        educations: {
          options: {
            fields: {
              includes: [],
              excludes: [],
              sorting: ['title', 'event', 'type', 'state', 'actions'],
              sortable: ['title', 'event', 'type', 'state'],
              labelKey: '',
              label: {},
              variant: {},
              class: {
                type: `cell-min-${this.$root.mobile.up.key} cell-align-center-${this.$root.mobile.up.key}`,
                state: `cell-min-${this.$root.mobile.up.key} cell-align-center-${this.$root.mobile.up.key}`,
                actions: `cell-min-${this.$root.mobile.up.key} cell-nowrap-${this.$root.mobile.up.key} cell-align-right-${this.$root.mobile.up.key}`
              },
              thClass: {},
              tdClass: {}
            }
          }
        },
        elearnings: {
          options: {
            fields: {
              includes: [],
              excludes: [],
              sorting: ['title', 'type', 'state', 'actions'],
              sortable: ['title', 'type', 'state'],
              labelKey: '',
              label: {},
              variant: {},
              class: {
                type: `cell-min-${this.$root.mobile.up.key} cell-align-center-${this.$root.mobile.up.key}`,
                state: `cell-min-${this.$root.mobile.up.key} cell-align-center-${this.$root.mobile.up.key}`,
                actions: `cell-min-${this.$root.mobile.up.key} cell-nowrap-${this.$root.mobile.up.key} cell-align-right-${this.$root.mobile.up.key}`
              },
              thClass: {},
              tdClass: {}
            }
          }
        },
        proofs: {
          options: {
            fields: {
              includes: [],
              excludes: [],
              sorting: ['title', 'completionDate', 'documents'],
              sortable: ['title', 'completionDate'],
              labelKey: '',
              label: {},
              variant: {},
              class: {
                documents: `cell-min-${this.$root.mobile.up.key} cell-nowrap-${this.$root.mobile.up.key} cell-align-right-${this.$root.mobile.up.key}`
              },
              thClass: {},
              tdClass: {}
            }
          }
        }
      }
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    entities () {
      return this.$store.getters[`${this.entityKey}/get`]
    },
    proofs () {
      return this.$store.getters[`${this.entityKey}/getProofs`]
    },
    educationEntities () {
      const LANGUAGE = this.$store.getters['gui/getLanguage']

      return this.entities
        .map(e => {
          const EVENT = e._event || {}
          const EVENTTYPE = e._activityType || {}
          const EVENT_DATES = (EVENT.details || {}).dates || []
          const EVENT_DETAILS = getEventDetails.bind(this)(EVENT_DATES)
          const DETAILLINK_ACTION = getDetailLinkAction.bind(this)(EVENT)
          const ACTIONS = [].concat(DETAILLINK_ACTION || [], e.actions || [])

          const ENTITY_STATUS_LABEL = e.statusLabel || {}
          const EVENT_TYPE_LABEL = EVENTTYPE.name || {}

          return {
            type: EVENT.eventType,
            typeVariant: EDUCATION_TYPE_VARIANTS[EVENT.eventType],
            typeLabel: EVENT_TYPE_LABEL[LANGUAGE] || EVENT.eventType,
            title: e.title,
            event: EVENT_DETAILS,
            state: e.statusType,
            stateVariant: EDUCATION_STATE_VARIANTS[e.statusType],
            stateLabel: ENTITY_STATUS_LABEL[LANGUAGE] || e.statusType,
            actions: ACTIONS
          }
        })

      function getEventDetails (eventDates = []) {
        return eventDates
          .map(eventDate => {
            const LOCATION_BOOKING = eventDate.locationBooking || {}
            const LOCATION = LOCATION_BOOKING.location || {}

            return {
              id: eventDate.id,
              instructors: eventDate.instructors || [],
              datetime: {
                date: eventDate.date || '',
                from: eventDate.dateTimeFrom || '',
                to: eventDate.dateTimeTo || ''
              },
              location: Object.assign({
                from: LOCATION_BOOKING.from,
                to: LOCATION_BOOKING.to,
                address: [LOCATION.street ? LOCATION.street : '', (LOCATION.zip && LOCATION.place) ? `${LOCATION.zip} ${LOCATION.place}` : '', LOCATION.country ? LOCATION.country : ''].filter(i => i).join(', '),
                locality: [LOCATION.building ? `${this.$T('event.BuildingLabel')} ${LOCATION.building}` : '', LOCATION.room ? `${this.$T('event.RoomLabel')} ${LOCATION.room}` : ''].filter(i => i).join(',')
              }, LOCATION)
            }
          })
      }

      function getDetailLinkAction (event = {}) {
        if (event.urlSlug) {
          return {
            name: {
              [LANGUAGE]: this.$T('event.DetailLinkLabel')
            },
            type: 'OpenDetailPage',
            url: this.$store.getters['gui/getBaseUrl']('education', event.urlSlug)
          }
        }

        return null
      }
    },
    proofsEntities () {
      return this.proofs
        .map(p => Object.assign(p, { state: p.status }))
    },
    tableEducations () {
      return new Table(
        this.educationEntities.filter(e => e.type !== EDUCATION_VARIANT_TYPES.elearning),
        this.table.educations.options.fields
      )
    },
    tableElearnings () {
      return new Table(
        this.educationEntities.filter(e => e.type === EDUCATION_VARIANT_TYPES.elearning),
        this.table.elearnings.options.fields
      )
    },
    tableProofs () {
      return new Table(
        this.proofsEntities,
        this.table.proofs.options.fields
      )
    }
  },
  methods: {
    getEntities () {
      this.$store.dispatch(`${this.entityKey}/get`)
    },
    setTableLabels () {
      Object.keys(this.table)
        .forEach(tKey => {
          this.table[tKey].options.fields.sorting
            .forEach(fKey => {
              const labelKey = `table.Labels.${fKey}`
              this.table[tKey].options.fields.label[fKey] = this.$TE(labelKey) ? this.$T(labelKey) : ' '
            })
        })
    },
    sortEducationTable (aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      if (key === 'event') {
        const aEventDate = aRow.event[0] !== undefined ? aRow.event[0].datetime.date : null
        const bEventDate = bRow.event[0] !== undefined ? bRow.event[0].datetime.date : null

        if (aEventDate === null || bEventDate === null) {
          return null
        }

        return aEventDate < bEventDate ? -1 : aEventDate > bEventDate ? 1 : 0
      }

      return null
    }
  },
  created () {
    this.getEntities()
    this.setTableLabels()
  },
  watch: {
    entities: {
      deep: true,
      handler () {
        this.setTableLabels()
      }
    }
  }
}
</script>

<style lang="scss">
$usereducations-event-gap: $spacer * 1.2 !default;
$usereducations-event-border: $border-width $border-style $border-color !default;

$usereducations-event-actionbuttons-gap: $spacer * 0.4 !default;
$usereducations-event-actionbuttons-font-size: $font-size-sm * 0.8 !default;

$usereducations-mobile-breakpoint: $mobile-breakpoint !default;

.usereducations {
  .b-table {
    .usereducations-event {
      + .usereducations-event {
        margin-top: $usereducations-event-gap * 0.5;
        padding-top: $usereducations-event-gap * 0.5;
        border-top: $usereducations-event-border;
      }
    }

    .event-actionbuttons {
      display: flex;
      margin: $usereducations-event-actionbuttons-gap * -0.5;

      .btn {
        display: block;
        margin: $usereducations-event-actionbuttons-gap * 0.5;
        font-size: $usereducations-event-actionbuttons-font-size;
        white-space: nowrap;
      }
    }
  }

  @include media-breakpoint-down($usereducations-mobile-breakpoint) {
    .b-table {
      .event-actionbuttons {
        flex-wrap: wrap;

        .btn {
          white-space: normal;
        }
      }
    }
  }
}
</style>
